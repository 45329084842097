<template>
  <div class="container">
    <div class="header">
      <div
        class="element"
        :class="{ active: type === 'artist' }"
        @click="setType('artist')"
      >
        Artist Settings
      </div>
      <div
        class="element"
        :class="{ active: type === 'company' }"
        v-if="showCompanySettings"
        @click="setType('company')"
      >
        Company Settings
      </div>
      <div
        class="element"
        :class="{ active: type === 'user' }"
        @click="setType('user')"
      >
        User Settings
      </div>
    </div>
    <div class="settings">
      <ArtistSettings v-if="type === 'artist'" />
      <CompanySettings v-if="type === 'company'" />
      <UserSettings v-if="type === 'user'" />
    </div>
  </div>
</template>

<script>
import ArtistSettings from "@/components/ArtistSettings.vue";
import CompanySettings from "@/components/CompanySettings.vue";
import UserSettings from "@/components/UserSettings.vue";

export default {
  name: "Settings",
  components: {
    ArtistSettings,
    CompanySettings,
    UserSettings
  },
  data() {
    return {
      type: "artist"
    };
  },
  mounted() {
    const type = this.$route.query.type;
    if (type) {
      this.type = type;
      // this.$router.replace({ query: {} });
    }
  },
  computed: {
    showCompanySettings() {
      return this.$store.getters["account/getUser"].company_users?.length > 0;
    }
  },
  methods: {
    setType(newType) {
      this.type = newType;
      this.$router.push({ query: { type: newType } });
    }
  },
  apollo: {}
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.container {
  .header {
    margin: 20px calc(5% + 3rem) 0;

    .element {
      background: white;
      color: #2e3440;
      display: inline-block;
      border-bottom: 1px solid #6d6e71;
      margin: 0;
      padding: 5px 20px;
      border-radius: 5px 5px 0px 0px;
      cursor: pointer;

      &.active {
        color: $accent;
        background: #ffffff;
        border: 1px solid #2e3440;
        border-bottom: none;
        border-bottom: 1px solid #ffffff;
      }
    }
  }
}
</style>
