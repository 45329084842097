<template>
  <div class="my-settings">
    <Loader
      v-if="
        $apollo.queries.profile.loading || $apollo.queries.myArtists.loading
      "
      size="50"
      color="#FF035A"
    />
    <div class="content" v-else>
      <span class="section-title">Account Details</span>
      <div class="section-content">
        <div class="profile-form">
          <span class="label">
            Email
          </span>
          <input type="text" class="text-input" v-model="editable.email" />
          <span class="label">
            What’s your full legal name?
          </span>
          <div class="inline">
            <input
              type="text"
              class="text-input"
              v-model="editable.first_name"
            />
            <input
              type="text"
              class="text-input"
              v-model="editable.last_name"
            />
          </div>
          <span class="label">
            Address
          </span>
          <AddressInput
            v-model="editable.address"
            title="Address"
            theme="light"
          />
          <div class="buttons">
            <button
              class="primary"
              :disabled="!updated || !valid || submitting"
              @click="updateProfile"
            >
              {{ submitting ? "Updating..." : "Update" }}
            </button>
          </div>
        </div>
      </div>
      <span class="section-title">Payment Methods</span>
      <span class="section-subtitle">
        Your charges will be deducted from the default card shown below. This
        can be changed by adding a new card and making it the default using the
        menu on the right.
      </span>
      <div class="section-content">
        <div class="payment-methods">
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th>Default</th>
                  <th>Type</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="loading-table"
                  v-if="$apollo.queries.paymentMethods.loading"
                >
                  <td colspan="3">
                    <div>
                      <span>Loading</span>
                      <div>
                        <Loader size="18" width="2" color="#FF035A" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="no-data" v-else-if="!paymentMethods.length">
                  <td colspan="3">
                    <div>
                      No Payment Methods Available
                    </div>
                  </td>
                </tr>
                <tr
                  v-for="{
                    id,
                    card: {
                      default: isDefault,
                      funding,
                      brand,
                      last4,
                      exp_month,
                      exp_year
                    }
                  } in paymentMethods"
                  :key="id"
                >
                  <td>
                    <div class="check" v-if="isDefault">
                      <img src="../assets/icons/Check.svg" />
                    </div>
                  </td>
                  <td>{{ funding === "credit" ? "Credit" : "Debit" }} Card</td>
                  <td>
                    <div class="payment-details">
                      <div class="brand-container">
                        <img :src="require(`@/assets/icons/${brand}.svg`)" />
                      </div>
                      <span class="card-number">• • • • - {{ last4 }}</span>
                      <span class="card-expiry">
                        EXP. {{ [exp_month, exp_year] | cardExpiry }}
                      </span>
                    </div>
                  </td>
                  <td class="actions">
                    <div class="icon" @click="toggleShowActions(id)">
                      <span class="dot" v-for="i in 3" :key="i" />
                    </div>
                    <div
                      class="menu"
                      :id="`actions-menu-${id}`"
                      v-show="actionsPaymentMethod === id"
                      @focusout="toggleShowActions()"
                      tabindex="1"
                    >
                      <span
                        class="item"
                        @click="updateDefaultPaymentMethod(id)"
                        v-if="!isDefault"
                      >
                        {{ updatingDefaultPM ? "Updating..." : "Set Default" }}
                      </span>
                      <span class="item" @click="removePaymentMethod(id)">
                        {{ removingPM ? "Removing..." : "Remove" }}
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- <span class="section-title" id="artist-plans">Artist Plans</span>
      <div class="section-content">
        <div class="artist-plans">
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th>Artist</th>
                  <th>Plan</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="{ id, name, avatar_url, plan } in myArtists"
                  :key="id"
                >
                  <td class="artist">
                    <img :src="avatar_url" v-if="avatar_url" />
                    <div class="initials" v-else>
                      {{ name | initials }}
                    </div>
                    <span>{{ name }}</span>
                  </td>
                  <td class="plan">
                    <span class="pro" v-if="plan === 'pro'">PRO</span>
                    <span class="base" v-else>Base</span>
                  </td>
                  <td class="actions">
                    <div class="buttons" v-if="plan === 'pro'">
                      <button
                        class="primary outline"
                        @click="initatePlanConfirmModal('base', id)"
                      >
                        Downgrade
                      </button>
                      <button class="primary">Payer</button>
                    </div>
                    <div class="buttons" v-else>
                      <button
                        class="primary"
                        @click="initatePlanConfirmModal('pro', id)"
                      >
                        Upgrade to Pro
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> -->
      <span class="section-title">Invoices</span>
      <div class="section-content">
        <div class="invoices">
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Type</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="loading-table"
                  v-if="$apollo.queries.invoices.loading"
                >
                  <td colspan="3">
                    <div>
                      <span>Loading</span>
                      <div>
                        <Loader size="20" color="#FF035A" />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="no-data" v-else-if="!invoices.length">
                  <td colspan="3">
                    <div>
                      No Invoices Available
                    </div>
                  </td>
                </tr>
                <tr
                  v-for="{
                    id,
                    amount_due,
                    period_start,
                    period_end,
                    payment_method: {
                      card: { funding, brand, last4 }
                    }
                  } in invoices"
                  :key="id"
                >
                  <td>${{ amount_due / 100 }}</td>
                  <td>{{ funding === "credit" ? "Credit" : "Debit" }} Card</td>
                  <td>
                    <div class="payment-details">
                      <div class="brand-container">
                        <img :src="require(`@/assets/icons/${brand}.svg`)" />
                      </div>
                      <span class="card-number">• • • • - {{ last4 }}</span>
                    </div>
                  </td>
                  <td class="text-center">
                    {{ period_start | date }} - {{ period_end | date }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="logout">
        <!--
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
          pretium, augue at aliquam viverra, neque nisi gravida arcu.
        </span>
        -->
        <button class="primary outline" @click="logout">
          Logout
        </button>
      </div>
    </div>
    <ConfirmPlan
      v-if="planUpdateParams"
      :plan-update-params="planUpdateParams"
      @close="planUpdateParams = null"
    />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import Loader from "@/components/Loader.vue";
import ConfirmPlan from "@/components/modals/ConfirmPlan.vue";
import AddressInput from "@/components/AddressInput.vue";

import GET_USER from "@/api/queries/GET_USER.gql";
import UPDATE_USER_PROFILE from "@/api/mutations/UPDATE_USER_PROFILE.gql";
import GET_MY_ARTISTS from "@/api/queries/GET_MY_ARTISTS.gql";
import SUBSCRIBE from "@/api/mutations/SUBSCRIBE.gql";
import SUBSCRIPTION_ADD_ARTIST from "@/api/mutations/SUBSCRIPTION_ADD_ARTIST.gql";
import SUBSCRIPTION_REMOVE_ARTIST from "@/api/mutations/SUBSCRIPTION_REMOVE_ARTIST.gql";
import GET_PAYMENT_METHODS from "@/api/queries/GET_PAYMENT_METHODS.gql";
import REMOVE_PAYMENT_METHOD from "@/api/mutations/REMOVE_PAYMENT_METHOD.gql";
import UPDATE_DEFAULT_PAYMENT_METHOD from "@/api/mutations/UPDATE_DEFAULT_PAYMENT_METHOD.gql";
import GET_INVOICES from "@/api/queries/GET_INVOICES.gql";

export default {
  name: "UserSettings",
  components: { Loader, ConfirmPlan, AddressInput },
  data() {
    return {
      editable: {},
      submitting: false,
      actionsPaymentMethod: null,
      planUpdateParams: null,
      removingPM: false,
      updatingDefaultPM: false
    };
  },
  watch: {
    profile(val) {
      setTimeout(() => {
        if (!this.$route.hash) return;
        const hash = this.$route.hash.split("#")[1];
        const el = document.getElementById(hash);
        if (el && val) el.scrollIntoView({ behavior: "smooth" });
        this.$router.push({ name: "UserSettings" });
      }, 500);
    }
  },
  computed: {
    updated() {
      const { editable, profile } = this;
      return Object.keys(profile).some(el => profile[el] !== editable[el]);
    },
    valid() {
      const {
        editable: { email, first_name, last_name, address }
      } = this;

      return !!email && !!first_name && !!last_name && !!address;
    },
    user() {
      return this.$store.getters["account/getUser"];
    },
    isPro() {
      return !!this.user?.subscription?.artist_subscriptions.find(
        ({ artist_id }) => artist_id === this.artist?.id
      );
    }
  },
  filters: {
    initials(name) {
      return name
        ?.split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    },
    cardExpiry(data) {
      const month = data[0] > 9 ? `${data[0]}` : `0${data[0]}`;
      const yearTwoDig = data[1] % 100;
      const year = yearTwoDig > 9 ? `${yearTwoDig}` : `0${yearTwoDig}`;
      return month + "/" + year;
    },
    date(timestamp) {
      return new Date(timestamp).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric"
      });
    }
  },
  updated: function() {
    this.$nextTick(function() {
      if (this.actionsPaymentMethod)
        document
          .querySelector(`#actions-menu-${this.actionsPaymentMethod}`)
          .focus();
    });
  },
  methods: {
    toggleShowActions(val) {
      if (this.removingPM || this.updatingDefaultPM) return;
      this.actionsPaymentMethod = val;
    },
    updateProfile() {
      this.submitting = true;

      const { id, email, first_name, last_name, address } = this.editable;

      const variables = {
        id,
        update: {
          email,
          first_name,
          last_name,
          address
        }
      };

      this.$apollo.mutate({
        mutation: UPDATE_USER_PROFILE,
        variables,
        update: (store, { data: { update_users_by_pk } }) => {
          this.$store.commit("account/updateUser", update_users_by_pk);
          this.submitting = false;
        }
      });
    },
    initatePlanConfirmModal(plan, id) {
      this.planUpdateParams = {
        plan,
        save: () => (plan === "pro" ? this.upgrade(id) : this.downgrade(id))
      };
    },
    upgrade(artist_id) {
      const { subscription, id, first_name, last_name } = this.$store.getters[
        "account/getUser"
      ];
      return this.$apollo.mutate({
        mutation: subscription ? SUBSCRIPTION_ADD_ARTIST : SUBSCRIBE,
        variables: { artist_id },
        update: store => {
          const data = store.readQuery({
            query: GET_MY_ARTISTS,
            variables: {
              userId: this.$store.getters["account/getUserId"]
            }
          });

          data.artists = data.artists.map(artist => {
            if (artist.id !== artist_id) return artist;
            return {
              ...artist,
              artist_subscriptions: [
                {
                  __typename: "artist_subscriptions",
                  subscription: {
                    __typename: "subscriptions",
                    user: {
                      __typename: "users",
                      id,
                      first_name,
                      last_name
                    }
                  }
                }
              ]
            };
          });

          store.writeQuery({
            query: GET_MY_ARTISTS,
            variables: {
              userId: this.$store.getters["account/getUserId"]
            },
            data
          });

          this.planUpdateParams = null;
        }
      });
    },
    downgrade(artist_id) {
      return this.$apollo.mutate({
        mutation: SUBSCRIPTION_REMOVE_ARTIST,
        variables: { artist_id },
        update: store => {
          const data = store.readQuery({
            query: GET_MY_ARTISTS,
            variables: {
              userId: this.$store.getters["account/getUserId"]
            }
          });

          data.artists = data.artists.map(artist => {
            if (artist.id !== artist_id) return artist;
            return { ...artist, artist_subscriptions: [] };
          });

          store.writeQuery({
            query: GET_MY_ARTISTS,
            variables: {
              userId: this.$store.getters["account/getUserId"]
            },
            data
          });

          this.planUpdateParams = null;
        }
      });
    },
    removePaymentMethod(payment_method) {
      if (this.removingPM || this.updatingDefaultPM) return;
      this.removingPM = true;
      return this.$apollo.mutate({
        mutation: REMOVE_PAYMENT_METHOD,
        variables: { payment_method },
        update: store => {
          const data = store.readQuery({
            query: GET_PAYMENT_METHODS
          });

          data.stripe_get_payment_methods = data.stripe_get_payment_methods.flatMap(
            item => {
              if (item.id !== payment_method) return [item];
              return [];
            }
          );

          store.writeQuery({
            query: GET_PAYMENT_METHODS,
            data
          });

          this.removingPM = false;
          this.actionsPaymentMethod = null;
        }
      });
    },
    updateDefaultPaymentMethod(payment_method) {
      if (this.removingPM || this.updatingDefaultPM) return;
      this.updatingDefaultPM = true;
      return this.$apollo.mutate({
        mutation: UPDATE_DEFAULT_PAYMENT_METHOD,
        variables: { payment_method },
        update: store => {
          const data = store.readQuery({
            query: GET_PAYMENT_METHODS
          });

          data.stripe_get_payment_methods = data.stripe_get_payment_methods.map(
            ({ id, card, ...rest }) => ({
              id,
              card: {
                ...card,
                default: id === payment_method
              },
              ...rest
            })
          );

          store.writeQuery({
            query: GET_PAYMENT_METHODS,
            data
          });

          this.updatingDefaultPM = false;
          this.actionsPaymentMethod = null;
        }
      });
    },
    logout() {
      this.$store.commit("account/logout");
      window.location.href = "/";
    }
  },
  apollo: {
    profile: {
      query: GET_USER,
      variables() {
        return { id: this.$store.getters["account/getUserId"] };
      },
      update({ users_by_pk }) {
        this.editable = { ...users_by_pk };
        return { ...users_by_pk };
      }
    },
    myArtists: {
      query: GET_MY_ARTISTS,
      variables() {
        return { userId: this.$store.getters["account/getUserId"] };
      },
      update({ artists }) {
        const sorted = [...artists];
        const id = this.$store.getters["account/getArtistId"];

        sorted.forEach((item, i) => {
          if (item.id === id) {
            sorted.splice(i, 1);
            sorted.unshift(item);
          }
        });

        return sorted.map(item => {
          const { artist_subscriptions, ...rest } = item;
          return {
            ...rest,
            plan: artist_subscriptions.length ? "pro" : "base"
          };
        });
      }
    },
    paymentMethods: {
      query: GET_PAYMENT_METHODS,
      update: ({ stripe_get_payment_methods }) => stripe_get_payment_methods
    },
    invoices: {
      query: GET_INVOICES,
      update: ({ stripe_get_invoices }) => stripe_get_invoices
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.my-settings {
  width: 100%;
  height: 100%;

  .loader-container {
    height: calc(100vh - 110px) !important;
  }

  .content {
    padding-top: 3rem;
    overflow-y: auto;
    height: 100%;

    &::-webkit-scrollbar {
      /* width of the entire scrollbar */
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      /* color of the tracking area */
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      /* color of the scroll thumb */
      background-color: $accent;
      /* roundness of the scroll thumb */
      border-radius: 5px;
    }

    .section-title {
      display: block;
      margin-left: calc(5% + 3rem);
      font-size: 1.15rem;
      font-weight: 600;
    }

    .section-subtitle {
      display: block;
      margin: 0.8rem calc(5% + 3rem) 0;
      font-weight: 200;
    }

    .section-content {
      margin: 0 5% 2rem;
      padding: 1.5rem 3rem;

      .profile-form {
        display: flex;
        flex-direction: column;

        .label {
          margin-bottom: 10px;
        }

        input {
          margin-bottom: 30px;
        }

        .inline {
          display: flex;
          justify-content: space-between;

          input {
            width: 48%;
          }
        }
      }

      .payment-methods,
      .invoices {
        .check {
          margin-top: 2px;
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $accent;
          overflow: hidden;
          border-radius: 50%;

          img {
            object-fit: cover;
            width: 12px;
            height: 12px;
          }
        }

        .payment-details {
          display: flex;
          align-items: center;

          .brand-container {
            width: 48px;
            display: flex;
            justify-content: center;

            img {
              height: 34px;
            }
          }

          span {
            margin-left: 1rem;
          }

          .card-number {
            font-weight: 600;
          }

          .card-expiry {
            font-weight: 600;
            color: $text-additional;
          }
        }

        .actions {
          text-align: right;
          padding: 0;
          position: relative;

          .icon {
            display: flex;
            flex-direction: column;
            background-color: #e0e0e0;
            align-items: center;
            justify-content: space-between;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            padding: 4px 0;
            margin-left: auto;

            &:hover {
              cursor: pointer;
              background-color: #c9c9c9;
            }

            .dot {
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background-color: $white;
            }
          }

          .menu {
            position: absolute;
            background-color: $bg;
            top: calc(50% - 12px);
            right: 0;
            display: flex;
            flex-direction: column;
            border: 1px solid #ebebeb;
            border-radius: 10px;
            z-index: 11;

            &:focus {
              outline: none;
            }

            .item {
              text-align: left;
              font-size: 0.8rem;
              white-space: nowrap;
              padding: 8px 12px;

              &:hover {
                background-color: #f3f1f1;
                cursor: pointer;
              }
            }
          }
        }
      }

      .plans {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .pro {
          border: 2px solid $accent;
        }

        .plan {
          width: 45%;
          background-color: $gray;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 24px 14px;
          border-radius: 20px;

          .details {
            display: flex;
            flex-direction: column;

            .name {
              font-size: 1.1rem;
            }

            .price {
              font-weight: 100;
            }
          }

          .check {
            margin-top: 2px;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $accent;
            overflow: hidden;
            border-radius: 50%;

            img {
              object-fit: cover;
              width: 15px;
            }
          }

          .action {
            button {
              margin-top: 2px;
              padding: 10px 18px 13px;
            }
          }

          .action-pro {
            flex-grow: 1;
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            align-items: center;
            height: 100%;

            .artists {
              flex-grow: 1;
              position: relative;
              height: 100%;

              @for $i from 1 through 100 {
                .artist:nth-child(#{$i}) {
                  position: absolute;
                  width: #{(10 - $i) * 4}px;
                  height: #{(10 - $i) * 4}px;
                  border: 2px solid $gray;
                  border-radius: 50%;
                  top: #{(($i - 1) * 2) + 3}px;
                  z-index: #{(9 - $i) * 2};

                  @if $i >1 {
                    opacity: 0.8;
                  }

                  font-size: #{(9 - $i) * 2}px;

                  @if $i < 3 {
                    padding-bottom: 2px;
                  } @else if $i ==3 {
                    padding-bottom: 1px;
                  }
                }
              }

              $offset: 0;

              @for $i from 1 through 100 {
                .artist:nth-last-child(#{$i}) {
                  right: #{$offset}px;
                }

                $width: ($i + 4) * 4;
                $offset: $offset + (($width) / 3);
              }

              .image {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
              }

              .initials {
                background-color: $black;
                border: 1px solid $accent;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $white;
              }

              .artist:nth-child(1)::after {
                content: "$";
                background-color: $accent;
                color: $white;
                font-size: 0.6rem;
                padding: 0 5px 1px;
                font-weight: 900;
                border-radius: 50%;
                position: absolute;
                right: -2px;
                top: -2px;
              }
            }

            .pen {
              margin-top: 2px;
              margin-left: 0.75rem;
              width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: $accent;
              overflow: hidden;
              border-radius: 50%;

              img {
                object-fit: cover;
                width: 15px;
              }
            }
          }
        }
      }

      .artist-plans {
        .artist {
          display: flex;
          align-items: center;

          img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }

          .initials {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 32px;
            width: 32px;
            font-family: sans-serif;
            font-size: 13px;
            border-radius: 50%;
            background-color: $black;
            border: 1px solid $accent;
            color: $white;
          }

          span {
            margin-left: 0.75rem;
            font-size: 0.95rem;
          }
        }

        .plan {
          vertical-align: middle;

          /* .base {
            font-size: 0.9rem;
          } */
          .pro {
            background-color: #eb355d;
            color: $white;
            font-size: 0.7rem;
            font-weight: 600;
            padding: 1px 2px 2px;
            border-radius: 3px;
          }
        }

        .actions {
          vertical-align: middle;

          .buttons {
            display: flex;
            width: 200px;
            margin-left: auto;

            button {
              height: 32px;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              padding: 0 15px 2px;
              margin: 0 2px;
            }
          }
        }
      }

      .loading-table {
        td {
          width: 100%;
          text-align: center;

          span {
            color: $accent;
            margin-right: 10px;
            margin-bottom: 4px;
          }

          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .no-data {
        td {
          width: 100%;
          text-align: center;

          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .logout {
      background-color: $bg;
      padding: 38px calc(5% + 3rem);
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        padding: 13px 40px;
        margin-left: 5rem;
      }
    }

    .buttons {
      display: flex;

      button {
        width: 136px;
        height: 44px;
      }
    }
  }
}
</style>
