<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="!saving ? $emit('close') : 0" class="icon">
                <ModalCloseIcon />
              </div>
            </slot>
          </div>
          <div class="modal-body">
            <span class="title">Upload your image</span>
            <div class="upload-section" v-if="!src">
              <input
                type="file"
                id="image-file"
                @change="onImageChange"
                v-show="false"
                accept=".jpg, .jpeg, .png"
              />
              <label
                for="image-file"
                class="upload cursor-pointer"
                @drop.prevent="handleDroppedImage"
                @dragover.prevent
              >
                <img src="../../assets/icons/Upload-Cloud2.svg" />
                <span>Drag your image here or click to upload</span>
              </label>
            </div>
            <ImageCropper v-else :src="src" @update="updateImage" />
            <div class="buttons">
              <button
                v-if="!src"
                class="primary outline"
                @click="$emit('close')"
              >
                Cancel
              </button>
              <button
                v-else
                class="primary outline"
                @click="src = null"
                :disabled="saving"
              >
                Back
              </button>
              <button
                class="primary"
                @click="submit"
                :disabled="saving || !src"
              >
                {{ saving ? "Saving..." : "Save" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import ImageCropper from "@/components/ImageCropper.vue";

import S3_UPLOAD from "@/api/mutations/S3_UPLOAD.gql";

export default {
  name: "ImageUploader",
  props: {
    uploadParams: Object
  },
  data() {
    return {
      src: null,
      croppedImage: null,
      file: {},
      saving: false
    };
  },
  components: {
    ImageCropper,
    ModalCloseIcon
  },
  methods: {
    onImageChange(e) {
      this.addImage(e.target.files);
    },
    handleDroppedImage({ dataTransfer }) {
      this.addImage(dataTransfer.files);
    },
    addImage(files) {
      if (!files) return;
      const image = files[0];
      if (this.accept && image.type.split("/")[0] !== this.accept) {
        return alert(`The image has to be of type "${this.accept}"`);
      }
      if (image.size > 15000000) {
        return alert("The image can't be larger than 15MBs");
      }

      this.file = image;

      const reader = new FileReader();
      reader.addEventListener("load", e => {
        this.src = e.target.result;
      });

      reader.readAsDataURL(image);
    },
    updateImage(val) {
      this.croppedImage = val;
    },
    async getUploadUrl() {
      const { name, type } = this.file;
      const o = name.split(".");
      const extension = `.${o[o.length - 1]}`;

      const {
        data: { s3_upload }
      } = await this.$apollo.mutate({
        mutation: S3_UPLOAD,
        variables: { extension, type }
      });

      return s3_upload.url;
    },
    async uploadFile(url, file, image) {
      return new Promise(function(resolve, reject) {
        const xhr = new XMLHttpRequest();

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve(xhr);
            } else {
              reject(xhr);
            }
          }
        };

        const buffer = Buffer.from(
          image.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );

        xhr.open("PUT", url);
        xhr.setRequestHeader("Content-Type", file.type);
        xhr.setRequestHeader("ContentEncoding", "base64");
        xhr.send(buffer);
      });
    },
    async submit() {
      try {
        this.saving = true;
        const url = await this.getUploadUrl();
        await this.uploadFile(url, this.file, this.croppedImage);
        return this.uploadParams.save(url.split("?")[0]);
      } catch (err) {
        alert(err);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.modal-container {
  width: 40vw;
  height: fit-content;
  margin: 0 5rem;
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 32px 32px 0;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 34px 60px;
  .title {
    font-size: 2rem;
    margin: 1rem 0;
  }
  .upload {
    width: calc(40vw - 68px);
    height: 300px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    border: 1px dashed #d0d0d0;
    border-radius: 6px;
    img {
      width: 50px;
    }
    span {
      font-size: 14px;
      margin-top: 1rem;
    }
  }
  .buttons {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    button {
      width: 136px;
      height: 44px;
    }
  }
}
</style>
