<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :dark="true" />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <h4>ADD {{ params.title.toUpperCase() }}</h4>
              <div class="inputs">
                <div class="form-group names">
                  <input
                    v-model="firstName"
                    id="first_name"
                    type="text"
                    class="text-input dark"
                    placeholder="First name"
                  />
                  <input
                    v-model="lastName"
                    id="last_name"
                    type="text"
                    class="text-input dark"
                    placeholder="Last name"
                  />
                </div>
                <div class="form-group">
                  <input
                    v-model="email"
                    id="email"
                    type="email"
                    class="text-input dark"
                    placeholder="Email address"
                  />
                </div>
              </div>
              <div class="buttons">
                <button class="primary outline dark" @click="$emit('close')">
                  Cancel
                </button>
                <button
                  class="primary"
                  @click="addUser"
                  :disabled="!firstName || !lastName || !email || submitting"
                >
                  {{ submitting ? "Adding user..." : "Add" }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";

import ADD_ARTIST_USER from "@/api/mutations/ADD_ARTIST_USER.gql";
import GET_ARTIST from "@/api/queries/GET_ARTIST.gql";

export default {
  name: "AddArtistUser",
  components: {
    ModalCloseIcon
  },
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      submitting: false
    };
  },
  computed: {},
  methods: {
    addUser() {
      const {
        firstName,
        lastName,
        email,
        params: { role, artist_id, company_id }
      } = this;
      const user_id = this.$store.getters["account/getUserId"];

      const artistUser = {
        user_id_inviter: user_id,
        role,
        access: "owner",
        artist_id,
        company_id,
        user: {
          data: {
            first_name: firstName,
            last_name: lastName,
            display_name: `${firstName} ${lastName}`,
            email,
            status: "invited"
          },
          on_conflict: {
            update_columns: ["email"],
            constraint: "users_email_key",
            where: { email: { _eq: email } }
          }
        }
      };

      this.submitting = true;

      this.$apollo.mutate({
        mutation: ADD_ARTIST_USER,
        variables: { artist_user: artistUser },
        update: (store, { data: { insert_artist_users_one } }) => {
          const data = store.readQuery({
            query: GET_ARTIST,
            variables: {
              id: this.$store.getters["account/getArtistId"],
              userId: this.$store.getters["account/getUserId"]
            }
          });

          const type =
            role === "artist" ? "artist_accounts" : "representatives";

          data.artists_by_pk[type] = [
            ...data.artists_by_pk[type],
            { ...insert_artist_users_one }
          ];

          store.writeQuery({
            query: GET_ARTIST,
            variables: {
              id: this.$store.getters["account/getArtistId"],
              userId: this.$store.getters["account/getUserId"]
            },
            data
          });

          this.submitting = false;
          this.$emit("close");
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  max-width: 600px;
  height: fit-content;
  margin: 0 5rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 32px 32px 0;
}

.modal-body {
  padding: 0 34px 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h4 {
    color: $white;
    margin-bottom: 32px;
  }
  .inputs {
    min-width: 20rem;
    margin-bottom: 32px;
    .form-group {
      margin-bottom: 18px;
      input {
        @include details-light();
        color: $white;
      }
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.22px;
        color: #fcfffc;
      }
    }
    .names {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      input {
        width: 48%;
      }
    }
  }
  .buttons {
    button {
      margin: 0 0.5rem;
      width: 136px;
      height: 44px;
    }
  }
}
</style>
