<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="!submitting ? $emit('close') : 0" class="icon">
                <ModalCloseIcon />
              </div>
            </slot>
          </div>
          <div class="modal-body">
            <span class="title">{{ text.title }}</span>
            <span v-if="!isActive" class="description">
              Become an Nvoko Pro Member today and secure unlimited
              collaborations for your entire catalog.
            </span>
            <span v-if="isActive" class="description">
              Canceling your Nvoko subscription means you will pay $49 per song moving forward.
            </span>
            <div class="plan">
              <span class="name">{{ text.plan }}</span>
              <span class="price">{{ text.price }}</span>
            </div>
            <div class="buttons">
              <button class="primary outline" @click="$emit('close')">
                Cancel
              </button>
              <button class="primary" :disabled="submitting" @click="submit">
                {{ submitting ? "Saving..." : text.button }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";

export default {
  name: "ConfirmPlan",
  props: {
    planUpdateParams: {
      type: Object,
      required: true
    }
  },
  components: {
    ModalCloseIcon
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {
    isActive() {
      return this.planUpdateParams.plan !== "pro";
    },
    text() {
      const upgrading = this.planUpdateParams.plan === "pro";
      return {
        title: upgrading ? "Upgrade to Pro" : "Downgrade to Base",
        plan: upgrading ? "Pro" : "Base",
        price: upgrading ? "$299/year" : "$49/song",
        button: upgrading ? "Upgrade" : "Downgrade"
      };
    }
  },
  methods: {
    submit() {
      this.submitting = true;
      this.planUpdateParams.save();
    }
  }
};
</script>

<style scoped lang="scss">
.modal-container {
  width: 40vw;
  height: fit-content;
  margin: 0 5rem;
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 32px 32px 0;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5rem 60px;
  .title {
    font-size: 40px;
    margin: 1.5rem 0;
  }
  .description {
    text-align: center;
    font-size: 16px;
  }
  .plan {
    margin-top: 1.5rem;
    padding: 8px 52px 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $bg;
    border-radius: 20px;
    .name {
      font-size: 32px;
      font-weight: 700;
    }
    .price {
      font-size: 16px;
    }
  }
  .buttons {
    width: 100%;
    margin-top: 2.5rem;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    button {
      width: 136px;
      height: 44px;
    }
  }
}
</style>
