<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close', 'cancelled')" class="icon">
                <ModalCloseIcon :dark="true" />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <h4>ADD COMPANY REPRESENTATIVE</h4>
              <div class="inputs">
                <div class="form-group names">
                  <input
                    v-model="value.firstName"
                    id="first_name"
                    type="text"
                    class="text-input dark"
                    placeholder="First name"
                  />
                  <input
                    v-model="value.lastName"
                    id="last_name"
                    type="text"
                    class="text-input dark"
                    placeholder="Last name"
                  />
                </div>
                <div class="form-group">
                  <input
                    v-model="value.email"
                    id="email"
                    type="email"
                    class="text-input dark"
                    placeholder="Email address"
                  />
                </div>
              </div>
              <div class="buttons">
                <button
                  class="primary outline dark"
                  @click="$emit('close', 'cancelled')"
                >
                  Cancel
                </button>
                <button
                  class="primary"
                  @click="addRep"
                  :disabled="!valid || adding"
                >
                  {{ adding ? "Adding..." : "Add" }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";

import GET_COMPANY from "@/api/queries/GET_COMPANY.gql";
import ADD_COMPANY_USER from "@/api/mutations/ADD_COMPANY_USER.gql";

export default {
  name: "AddRepresentative",
  components: {
    ModalCloseIcon
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    valid: {
      type: Boolean,
      required: true
    },
    companyId: {
      type: String,
      required: false
    },
    onboarded: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      adding: false
    };
  },
  computed: {},
  methods: {
    addRep() {
      if (!this.onboarded) return this.$emit("close", "success");
      this.adding = true;
      this.$apollo.mutate({
        mutation: ADD_COMPANY_USER,
        variables: {
          companyUser: {
            company_id: this.companyId,
            user: {
              data: {
                first_name: this.value.firstName,
                last_name: this.value.lastName,
                email: this.value.email
              }
            }
          }
        },
        update: (store, { data: { insert_company_users_one } }) => {
          const data = store.readQuery({
            query: GET_COMPANY,
            variables: {
              userId: this.$store.getters["account/getUserId"]
            }
          });

          data.companies[0].company_users = [
            ...data.companies[0].company_users,
            insert_company_users_one
          ];

          store.writeQuery({
            query: GET_COMPANY,
            variables: {
              userId: this.$store.getters["account/getUserId"]
            },
            data
          });

          this.adding = false;
          return this.$emit("close", "success");
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  max-width: 600px;
  height: fit-content;
  margin: 0 5rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 32px 32px 0;
}

.modal-body {
  padding: 0 34px 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h4 {
    color: $white;
    margin-bottom: 32px;
  }
  .inputs {
    min-width: 20rem;
    margin-bottom: 32px;
    .form-group {
      margin-bottom: 18px;
      input {
        @include details-light();
        color: $white;
      }
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.22px;
        color: #fcfffc;
      }
    }
    .names {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      input {
        width: 48%;
      }
    }
  }
  .buttons {
    button {
      margin: 0 0.5rem;
      width: 136px;
      height: 44px;
    }
  }
}
</style>
