var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-settings"},[(_vm.$apollo.queries.profile.loading)?_c('Loader',{attrs:{"size":"50","color":"#FF035A"}}):_c('div',{staticClass:"content"},[_c('span',{staticClass:"section-title"},[_vm._v("Company Details")]),_c('div',{staticClass:"section-content"},[_c('div',{staticClass:"profile-form"},[_c('span',{staticClass:"label"},[_vm._v(" Company Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editable.name),expression:"editable.name"}],staticClass:"text-input",attrs:{"type":"text"},domProps:{"value":(_vm.editable.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.editable, "name", $event.target.value)}}}),_c('span',{staticClass:"label"},[_vm._v(" Address ")]),_c('AddressInput',{attrs:{"title":"Address","theme":"light"},model:{value:(_vm.editable.address),callback:function ($$v) {_vm.$set(_vm.editable, "address", $$v)},expression:"editable.address"}}),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"primary",attrs:{"disabled":!_vm.updated || !_vm.valid || _vm.submitting},on:{"click":_vm.updateProfile}},[_vm._v(" "+_vm._s(_vm.submitting ? "Updating..." : "Update")+" ")])])],1)]),_c('div',{staticClass:"section-content"},[_c('div',{staticClass:"users"},[_c('div',{staticClass:"title"},[_vm._v("Representatives")]),_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("CONTACT")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("ACCESS")])])]),_c('tbody',_vm._l((_vm.activeUsers),function(ref){
var id = ref.id;
var access = ref.access;
var user = ref.user;
return _c('tr',{key:id},[_c('td',{staticClass:"user"},[_c('div',{staticClass:"user-dp"},[(user.avatar_url)?_c('img',{attrs:{"src":user.avatar_url}}):_c('div',{staticClass:"initials"},[_vm._v(" "+_vm._s(_vm._f("initials")((user.first_name + " " + user.last_name)))+" ")])]),_c('div',{staticClass:"user-details"},[_c('span',[_vm._v(_vm._s(user.first_name + " " + user.last_name))]),_c('span',[_vm._v(_vm._s(user.email))])])]),_c('td',[_c('CustomSelect',{attrs:{"val":access,"default":"Contributor","options":[
                    { value: 'contributor', label: 'Contributor' },
                    { value: 'full', label: 'Full Access' }
                  ],"no-margin":""},on:{"setValue":function (value) { return _vm.updateCompanyUser(id, 'access', value); }}})],1),(_vm.updating.includes(id))?_c('td',[_c('Loader',{attrs:{"color":"#FF035A","size":"24"}})],1):(_vm.canUpdateTeam && _vm.userId !== user.id)?_c('td',[_c('div',{staticClass:"remove-container"},[_c('button',{staticClass:"remove",on:{"click":function($event){return _vm.updateCompanyUser(id, 'status', 'removed')}}},[_c('span',{staticClass:"minus"})])])]):_vm._e()])}),0)]),_c('div',{staticClass:"row add",on:{"click":_vm.toggleAddRepresentativeModal}},[_c('button',{staticClass:"add-button"},[_vm._v("+")]),_c('span',[_vm._v("Add Another Representative")])])])]),_c('div',{staticClass:"section-content"},[_c('div',{staticClass:"users"},[_c('div',{staticClass:"title"},[_vm._v("Artists")]),_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("ARTIST")]),_c('th',{staticClass:"right"},[_vm._v("RECORDING AGREEMENT")]),_c('th',{staticClass:"right"},[_vm._v("(THIRD-PARTY)"),_c('br'),_vm._v("RECORDING AGREEMENT")])])]),_c('tbody',_vm._l((_vm.profile.company_artists),function(ref){
              var id = ref.id;
              var artist = ref.artist;
              var recording_agreement = ref.recording_agreement;
              var recording_agreement_third_party = ref.recording_agreement_third_party;
return _c('tr',{key:id},[_c('td',{staticClass:"user"},[_c('div',{staticClass:"user-dp"},[(artist.avatar_url)?_c('img',{attrs:{"src":artist.avatar_url}}):_c('div',{staticClass:"initials"},[_vm._v(" "+_vm._s(_vm._f("initials")(artist.name))+" ")])]),_c('div',{staticClass:"user-details-one"},[_c('span',[_vm._v(_vm._s(artist.name))])])]),_c('td',{staticClass:"right"},[_c('CustomSelect',{staticClass:"rep-selector",attrs:{"options":[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' }
                  ],"val":recording_agreement,"default":"Has recording agreement?","noMargin":""},on:{"setValue":function (value) { return _vm.updateCompanyArtist(id, 'recording_agreement', value); }}})],1),_c('td',{staticClass:"right"},[_c('CustomSelect',{staticClass:"rep-selector",attrs:{"options":[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' }
                  ],"val":recording_agreement_third_party,"default":"Has recording agreement with 3rd party?","noMargin":""},on:{"setValue":function (value) { return _vm.updateCompanyArtist(
                        id,
                        'recording_agreement_third_party',
                        value
                      ); }}})],1),_c('td',[(_vm.updating.includes(id))?_c('Loader',{attrs:{"color":"#FF035A","size":"24"}}):_c('div',{staticClass:"no-loader"})],1)])}),0)]),_c('div',{staticClass:"row add",on:{"click":_vm.toggleAddArtistModal}},[_c('button',{staticClass:"add-button"},[_vm._v("+")]),_c('span',[_vm._v("Add Artist / Producer")])])])])]),(_vm.addingRepresentative)?_c('AddRepresentative',{attrs:{"valid":_vm.newRepValid,"companyId":_vm.profile.id,"onboarded":true},on:{"close":_vm.toggleAddRepresentativeModal},model:{value:(_vm.newRep),callback:function ($$v) {_vm.newRep=$$v},expression:"newRep"}}):_vm._e(),(_vm.addingArtist)?_c('AddArtist',{attrs:{"valid":_vm.newArtistValid,"companyId":_vm.profile.id,"companyName":_vm.profile.name,"onboarded":true},on:{"close":_vm.toggleAddArtistModal},model:{value:(_vm.newArtist),callback:function ($$v) {_vm.newArtist=$$v},expression:"newArtist"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }