<template>
  <div>
    <div class="img-container">
      <img ref="image" :src="src" height="auto" width="100%" />
    </div>
  </div>
</template>

<script>
import Cropper from "cropperjs";
export default {
  name: "ImageCropper",
  data() {
    return {
      cropper: {},
      image: {}
    };
  },
  props: {
    src: String
  },
  mounted() {
    this.image = this.$refs.image;
    this.cropper = new Cropper(this.image, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1,
      guides: false,
      cropBoxResizable: true,
      autoCropArea: 1,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas();
        this.$emit("update", canvas.toDataURL("image/png"));
      }
    });
  }
};
</script>

<style lang="scss">
.img-container {
  /* width: 80%; */
  img {
    display: block;
    max-width: 100%;
    max-height: 60vh;
  }
}

.cropper-container,
.cropper-modal,
.img-container img {
  border-radius: 20px;
}

.cropper-view-box {
  border-radius: 50%;
  border: 1px dashed $white;
  outline-color: rgba(100, 100, 100, 0.3);
}

.cropper-line {
  background-color: $white;
}

.cropper-point {
  height: 3px;
  width: 3px;
  background-color: rgba(255, 255, 255, 0.9);
}

.line-n,
.line-s {
  height: 4px !important;
}

.line-e,
.line-w {
  width: 4px !important;
}
</style>
